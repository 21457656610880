import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {lastValueFrom} from 'rxjs';
import {AppState} from '../../../reducers/reducer';
import {Store} from '@ngrx/store';
import jwt_decode from 'jwt-decode';
import {CondOperator, RequestQueryBuilder} from '@nestjsx/crud-request';
import {TraineeModel} from '../../models/trainee.model';
import {PrevediDto} from "../../dtos/prevedi.dto";
import * as dayjs from "dayjs";
import {LocalCeMemberModel} from "../../models/local-ce-member.model";
import {HttpCallerService} from "../http-caller.service";
import { TraineeWorkContractModel, TraineeWorkContractModelFactory } from '../../models/trainee-work-contract.model';
import { InverseTranslateTraineeCategory, TraineeCategoryTranslationEnum } from '../../enums/trainee-category.enum';

@Injectable({
  providedIn: 'root'
})
export class TraineeService extends HttpCallerService {

  prevediToken: any = undefined;
  readonly LOCAL_BOARD_CODE: string = "BS00";

  constructor(protected readonly http: HttpClient,
              private readonly store: Store<AppState>) {
    super(http);
  }

  async updatePrevediToken(): Promise<void> {
    const body = {
      username: environment.prevediUser,
      password: environment.prevediPassword
    };
    const a$ = this.http.post<any>(environment.basePrevediPath + '/auth/login', body);
    const data = await lastValueFrom(a$);
    this.prevediToken = data.token.jwt;
  }

  async getCompanyEmployeesFromPrevedi(companyFiscalCode: string): Promise<PrevediDto[]> {

    await this.refreshPrevediToken();

    const headers =  {
      Authorization: `Bearer ${this.prevediToken}`
    }

    const realToken: any = jwt_decode(this.prevediToken);

    const dateToUse = new Date();
    dateToUse.setMonth(dateToUse.getMonth() - environment.monthsForPrevediCheck);
    const dateString = dayjs(dateToUse).format('YYYY-MM');
    const url = environment.basePrevediPath + `/previnet-lav?s={"companyFiscalCode": {"$eq": "${companyFiscalCode}"}, "lastCompetence": {"$gte": "${dateString}"}}`
    const a$ = this.http.get<any>(url, {headers});
    const data = await lastValueFrom(a$);
    return data.data;
  }

  async refreshPrevediToken(): Promise<void> {

    if (!this.prevediToken) {
      await this.updatePrevediToken();
      return;
    }

    const realToken: any = jwt_decode(this.prevediToken);

    // It must update the token 5 minutes before expiration date
    if (new Date(realToken.exp * 1000) < new Date(Date.now() + (5 * 60000))) {
      await this.updatePrevediToken();
    }
  }

  async getTraineeWithData(id: string): Promise<TraineeModel> {
    return await this.get<TraineeModel>('/trainee/get-one/' + id);
  }

  async getLocalCeMembers(companyFiscalCode: string): Promise<LocalCeMemberModel[]> {
    let res: LocalCeMemberModel[]  = [];
    let currentPage = 0;
    let pagesCount = 1;
    do {
      currentPage++;
      const query = RequestQueryBuilder.create();
      query
        .setFilter({field: 'companyFiscalCode', operator: CondOperator.EQUALS, value: companyFiscalCode})
        .setFilter({field: 'enabled', operator: CondOperator.EQUALS,  value: true})
        .setPage(currentPage);

      const data: any = await this.get('/local-ce-member?' + query.query());
      currentPage = data.page;
      pagesCount = data.pageCount;
      res = [...res, ...data.data];
    } while (currentPage < pagesCount);
    return res;
  }

  async updateTrainee(id: string, body: TraineeModel){
    const trainee$ = this.http.patch(environment.backendBasePath + '/trainee/' + id, body);
    return await lastValueFrom(trainee$);
  }

  findWorkContractWithFiscalCode(workContracts: TraineeWorkContractModel[], fiscalCode?: string) {
    if (fiscalCode && workContracts?.length) {
      return workContracts.find(x => x.trainee?.fiscalCode === fiscalCode);
    } else {
      return undefined;
    }
  }

  async getTraineeByFiscalCode(fiscalCode?: string) {
    if (!fiscalCode) {
      return null;
    }

    const qb = new RequestQueryBuilder();

    qb.search({fiscalCode});

    const data = await this.get<{data: TraineeModel[]}>(`/trainee?${qb.query()}`);

    return data.data[0];
  }

  async getLocalCeMembersWorkContracts(companyFiscalCode: string, workContracts: TraineeWorkContractModel[]) {
    const companyLocalCeMembers = await this.getLocalCeMembers(companyFiscalCode);
    const result = []

    for (const element of companyLocalCeMembers) {
      const oldWorkContract = this.findWorkContractWithFiscalCode(workContracts, element.fiscalCode);

      if (!oldWorkContract) {
        const data = TraineeWorkContractModelFactory.createFromWorkerRegistry(element);

        data.inLocalCE = true;
        data.inOtherCE = false;
        data.type = element.type;
        result.push(data);
      }
    }

    return result;
  }

  async getPrevediMembersWorkContracts(companyFiscalCode: string, existingWorkContracts: TraineeWorkContractModel[]) {
    const prevediMembers = await this.getCompanyEmployeesFromPrevedi(companyFiscalCode);
    const prevediWorkContracts = prevediMembers.map(pm => ({
      ...TraineeWorkContractModelFactory.createFromWorkerRegistry(pm),
      type: InverseTranslateTraineeCategory(pm.task as TraineeCategoryTranslationEnum),
      inLocalCE: pm.boardName.includes(this.LOCAL_BOARD_CODE),
      inOtherCE: !pm.boardName.includes(this.LOCAL_BOARD_CODE),
    }));
    const fiscalCodes = [...new Set(prevediWorkContracts.map(wc => wc.trainee?.fiscalCode))];
    const result = [];

    for (const fiscalCode of fiscalCodes) {
      const oldWorkContract = this.findWorkContractWithFiscalCode(existingWorkContracts, fiscalCode);

      if (!oldWorkContract) {
        const traineePrevediWorkContracts: TraineeWorkContractModel[] = prevediWorkContracts.filter(wc => wc.trainee?.fiscalCode === fiscalCode);
        const prevediWc = traineePrevediWorkContracts.reduce((a, b) => ({
          ...a,
          type: b.type,
          inLocalCE: a.inLocalCE || b.inLocalCE,
          inOtherCE: a.inOtherCE || b.inOtherCE,
        }));

        result.push(prevediWc);
      }
    }

    return result;
  }
}
