import {Component, Input, OnInit} from '@angular/core';
import {TraineeWorkContractModel} from "../../../shared/models/trainee-work-contract.model";
import {FormBuilder, FormControl} from "@angular/forms";
import {
  GetTraineeTypesWithLabel, InverseTranslateTraineeCategory,
} from "../../../shared/enums/trainee-category.enum";
import {TraineeModelFactory} from "../../../shared/models/trainee.model";
import {TraineeWorkContractService} from "../../services/trainee-work-contract.service";
import {MatDialogRef} from "@angular/material/dialog";

import {
  TraineeContractEditDialogComponent
} from "../trainee-contract-edit-dialog/trainee-contract-edit-dialog.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TraineeService} from "../../../shared/services/trainee/trainee.service";
@Component({
  selector: 'app-trainee-contract-form',
  templateUrl: './trainee-contract-form.component.html',
  styleUrls: ['./trainee-contract-form.component.scss']
})
export class TraineeContractFormComponent implements OnInit {

  @Input() editable!: boolean;
  @Input() workContract!: TraineeWorkContractModel;

  traineeCategory = GetTraineeTypesWithLabel();
  disabled: boolean = false;

  form = this.fb.group({
    email: new FormControl(),
    phone: new FormControl(),
    type: new FormControl(),
    companyFiscalCode: new FormControl(),
    inLocalCE: new FormControl(),
    inOtherCE: new FormControl(),
    active: new FormControl(),
  });


  constructor(
    private readonly fb: FormBuilder,
    private readonly workContractService: TraineeWorkContractService,
    private dialogRef: MatDialogRef<TraineeContractEditDialogComponent>,
    private readonly snackBar: MatSnackBar,
    ) {}

  ngOnInit(){
    if (!this.editable) {
      this.form.disable();
    }
    this.fillForm();
  }

  fillForm(){
    if(this.workContract.trainee){
      this.form.patchValue(this.workContract);
      const controls = this.form.controls;
      controls.companyFiscalCode.disable();
    }
  }

  async onSubmit(){
    const formValues = this.form.value;
    if (formValues.inLocalCE != this.workContract.inLocalCE ||
      formValues.inOtherCE != this.workContract.inOtherCE ||
      InverseTranslateTraineeCategory(formValues.type) != this.workContract.type) {
      this.workContract.selfCertified = true;
      this.workContract.selfCertifiedDate = new Date();
    }

    if(!!formValues.inLocalCE && !!formValues.inOtherCE){
      this.snackBar.open("Il contratto non può appartenere a 2 casse edili", 'Ok',{duration: 2000});
    }else{
      if(this.form.valid){
        this.workContract = {
          ...this.workContract,
          ...this.form.getRawValue() as Partial<TraineeWorkContractModel>
        }
        await this.saveData();
      }
    }
  }

  async saveData(){
    await this.workContractService.save(this.workContract);
    this.snackBar.open("Contratto dipendente modificato correttamente", 'Ok', {duration: 1500});
    this.dialogRef.close(true);
  }

}
