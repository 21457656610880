<div class="larger-form-fields">
  <form [formGroup]="form" flex-layout="column" style="overflow: auto; height: 100%;">
    <div class="fields-fill">
      <div class="field">
        <label> Codice fiscale azienda</label>
        <mat-form-field appearance="outline">
          <input matInput formControlName="companyFiscalCode"/>
        </mat-form-field>
      </div>
      <div class="field">
        <label> Email </label>
        <mat-form-field appearance="outline">
          <input matInput formControlName="email"/>
        </mat-form-field>
      </div>
      <div class="field">
        <label> Telefono </label>
        <mat-form-field appearance="outline">
          <input type = "number" matInput formControlName="phone"/>
        </mat-form-field>
      </div>
      <div class="field">
        <label> Tipo di corsista </label>

        <mat-form-field appearance="outline">
          <mat-select formControlName="type" required>
            <mat-option *ngFor="let option of traineeCategory" [value]="option.value">{{option.title}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="field">
        <label> Stato del contratto </label>
        <mat-form-field appearance="outline">
          <mat-select matInput formControlName="active" required>
            <mat-option [value]="true">Attivo</mat-option>
            <mat-option [value]="false">Sospeso</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div style="display: flex">
        <div class="field" style="width: auto; margin-left: 10px">
          <label> Iscritto CAPE </label>
          <mat-checkbox [checked]="workContract.inLocalCE" formControlName="inLocalCE" ></mat-checkbox>
        </div>
        <div class="field"  style="width: auto; margin-left: 10px">
          <label> Iscritto altra cassa </label>
          <mat-checkbox [checked]="workContract.inOtherCE" formControlName="inOtherCE"></mat-checkbox>
        </div>
      </div>

    </div>
    <button *ngIf="editable" mat-raised-button color="primary" (click) = onSubmit() [disabled]="disabled" style="margin-top: 20px">Salva</button>
  </form>
</div>
