import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from './login/components/login.component';
import {environment} from "../environments/environment";
import {UserRegistrationLinkComponent} from "./user/components/user-registration-link/user-registration-link.component";
import {MsalWithRedirectGuard} from "./admin/guards/admin.guard";
import {MsalGuard, MsalRedirectComponent} from "@azure/msal-angular";

const guards: any[] = environment.enableMsal ? [MsalWithRedirectGuard] : [];

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'public',
    loadChildren: () => import('./public/public-routing.module').then(m => m.PublicRoutingModule)
  },
  {
    path:  "registrations/:id",
    component: UserRegistrationLinkComponent,
  },
  {
    path: '',
    loadChildren: () => import('./home/home-routing.module').then(m => m.HomeRoutingModule),
    canActivate: guards
  },
  { path: '**', redirectTo: 'login', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
