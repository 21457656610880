import {Injectable} from '@angular/core';
import {MsalService} from '@azure/msal-angular';
import {environment} from '../../../../environments/environment';
import {loginSuccess, logout} from '../../../login/actions/auth.actions';
import {AppModule} from '../../../app.module';
import {Store} from '@ngrx/store';
import {getToken, getUser} from '../../../login/selectors/auth.selector';
import jwt_decode from 'jwt-decode';
import {catchError, concatMap, lastValueFrom, Observable, take, throwError} from 'rxjs';
import {tap} from "rxjs/operators";
import {LocalStorageVariablesEnum} from "../../enums/local-storage-variables.enum";
import { AuthenticationResult } from '@azure/msal-browser';
import { EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AzureAdService {

  signInAndUpUserFlowConfiguration: any;
  constructor(private readonly msalService: MsalService,
              private readonly store: Store<AppModule>) {
    this.signInAndUpUserFlowConfiguration = {
      authority: environment.azureSingUpSignInEndpoint,
      scopes: ['openid', 'offline_access']
    };

  }

   getUserFromLoginPopupAndSaveJwt(): Observable<AuthenticationResult> {
      const c = this.msalService.loginPopup(this.signInAndUpUserFlowConfiguration);
      c.subscribe(async val => {
        this.store.dispatch(loginSuccess({accessToken: val.idToken, user: val.account}));
      });
      return c;
  }

  async getLastToken(): Promise<any> {
    const a$ = this.store.select(getToken).pipe(take(1));
    const token = await lastValueFrom(a$);
    return jwt_decode(token);
  }

  async refreshToken(forceRefresh = false): Promise<void> {
    const jwt = await this.getLastToken();
    const b$ = this.store.select(getUser).pipe(take(1));
    const user = await lastValueFrom(b$);

    if (new Date(jwt.exp * 1000) < new Date(Date.now() - (5 * 60000)) || forceRefresh) {
      this.msalService.acquireTokenSilent({
        ...this.signInAndUpUserFlowConfiguration,
        account: user
      }).subscribe(async val => {
        this.store.dispatch(loginSuccess({accessToken: val.idToken, user: val.account}));
      });

    }
  }

  refreshTokenNotAsync() {
    console.log("parte richiesta refresh"); // Il problema è che le richieste di refresh dopo la prima partono prima ancora che la prima abbia successo
    localStorage.setItem('isRefreshing', JSON.stringify(true))

    const user = localStorage.getItem(LocalStorageVariablesEnum.user);
    this.msalService.acquireTokenSilent({...this.signInAndUpUserFlowConfiguration, account: user});

    return this.msalService.acquireTokenSilent({...this.signInAndUpUserFlowConfiguration, account: user}).pipe(
      tap((val) => {
        localStorage.setItem('isRefreshing', JSON.stringify(false));
        return this.store.dispatch(loginSuccess({accessToken: val.idToken, user: val.account}));
      }),
      catchError((e: any) => {
        localStorage.setItem('isRefreshing', JSON.stringify(false));
        this.store.dispatch(logout());
        return throwError(() => e);
      }),
    );
  }

}
