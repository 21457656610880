import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {CourseModel} from '../../shared/models/course.model';
import {RequestQueryBuilder} from "@nestjsx/crud-request";
import {HttpCallerService} from "../../shared/services/http-caller.service";

@Injectable({
  providedIn: 'root'
})
export class CourseService extends HttpCallerService{

  constructor(protected readonly http: HttpClient) {
    super(http);
  }

  async createCourse(data: CourseModel): Promise<void> {
    await this.post('/course', data);
  }

  async updateCourse(data: CourseModel): Promise<void> {
    await this.patch('/course/' + data.id, data);
  }

  async getCourseWithCheckCodes(id: string): Promise<CourseModel>{
    const query = RequestQueryBuilder.create();
    query.setJoin({field: "checkCodes"});
    return await this.getCourse(id, query);
  }

  async getCategories(): Promise<any> {
    return await this.get('/course/get-categories');
  }

  async getAllOpenEditions(id: number | undefined) {
    return await this.get(`/course/get-open-editions/${id}`);
  }

  async getCourse(id: string, queryBuilder: RequestQueryBuilder): Promise<CourseModel>{
    const path = '/course/' + id + '?' + queryBuilder.query();

    return await this.get<CourseModel>(path);
  }

  async getCourseWithPreRegs(id: number) {
    const query = RequestQueryBuilder.create();
    query.setJoin([
      {field: "preRegistrations"},
      {field: "preRegistrations.workContract"},
      {field: "preRegistrations.workContract.company"},
      {field: "preRegistrations.workContract.trainee"},
      {field: "preRegistrations.traineeDeclaration"},
    ]);
    return await this.getCourse(id.toString(), query);
  }
}
