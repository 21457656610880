import {Injectable} from '@angular/core';
import {HttpCallerService} from "../../shared/services/http-caller.service";
import { HttpClient } from "@angular/common/http";
import {TraineeWorkContractModel} from "../../shared/models/trainee-work-contract.model";
import {environment} from "../../../environments/environment";
import {lastValueFrom} from "rxjs";
import {PaginatedResponseDto} from "../../shared/dtos/paginated-response.dto";
import {CondOperator, RequestQueryBuilder} from "@nestjsx/crud-request";
import {getLatestData} from "../../shared/utils/utils";

@Injectable({
  providedIn: 'root'
})
export class TraineeWorkContractService extends HttpCallerService{

  constructor(protected readonly http: HttpClient) {
    super(http);
  }

  async save(body: TraineeWorkContractModel){
    return await this.post<TraineeWorkContractModel>('/trainee-work-contract', body);
  }

  // Metodo usato solo nella parte di impresa al momento. La modifica dovrebbe essere indolore dato il pa
  async getMany(query: string = ""){
    const address = `/trainee-work-contract` + (query ? `?${query}` : "");
    const data = await this.get<PaginatedResponseDto<TraineeWorkContractModel>>(address);

    return data.data;
  }

  async getEmployeesWithPrivacyQuery(traineeData: string, companyData: string, exactCompany?: boolean): Promise<TraineeWorkContractModel[]>{
    const queryExactCompanySearch = exactCompany ? "&exactCompany=true" : "";
    const queryString = `traineeData=${traineeData}&companyData=${companyData}` + queryExactCompanySearch;
    const a$ = this.http.get<TraineeWorkContractModel[]>(environment.backendBasePath + '/trainee-work-contract/get-trainee-with-privacy-query?' + queryString);
    return await lastValueFrom(a$);
  }

  async getEmployeesByDataAndCompanyName(traineeData: string, companyId: number): Promise<TraineeWorkContractModel[]>{
    const query = RequestQueryBuilder.create();
    query
      .setFilter({field: 'company.id', operator: CondOperator.EQUALS, value: companyId})
      .setFilter({field: 'active', operator: CondOperator.EQUALS,  value: true})
      .setJoin([{field: 'company', select: ['id']}, {field: 'trainee'}])
    return this.getMany(query.query());
  }

  filterEmployeesWithPrivacyQuery(workContracts: TraineeWorkContractModel[], traineeData: string) {
    return workContracts.filter(this.privacyQueryPredicate(traineeData));
  }

  private privacyQueryPredicate(traineeData: string): (wk: TraineeWorkContractModel) => boolean {
    return (workContract) => {
      if (!workContract || !workContract.trainee) {
        return false;
      }
      const trainee = workContract.trainee;
      const lowercaseTraineeData = traineeData.toLowerCase();
      const like1 = (trainee.firstName ?? "").toLowerCase().includes(lowercaseTraineeData);
      const like2 = (trainee.lastName ?? "").toLowerCase().includes(lowercaseTraineeData);
      const like3 = (trainee.fiscalCode ?? "").toLowerCase().includes(lowercaseTraineeData);
      return like1 || like2 || like3;
    }
  }

  static compareByTraineeSurname(first: TraineeWorkContractModel, second: TraineeWorkContractModel) {
    if (!first.trainee) {
      return -1;
    }
    if (!second.trainee) {
      return 1;
    }
    return first.trainee.lastName.localeCompare(second.trainee.lastName);
  }

   sortWorkContractsByStatusAndDate(traineeWorkContracts : any){
    traineeWorkContracts.sort((a: any, b: any) => {
      if (a.active === b.active) {
        return getLatestData(a.createdDate, b.createdDate);
      }
      return a.active ? -1 : 1;
    });

    return traineeWorkContracts;
  }
}
