import {StringEnumToArray} from "../utils/utils";

export enum TraineeCategoryEnum{
  worker = 'worker',
  clerk = 'clerk',
  employer = 'employer',
  other = 'other',
  unemployed = 'unemployed',
  workingPartner = 'workingPartner'
}

export enum TraineeCategoryTranslationEnum{
  worker = 'Operaio',
  employee = 'Impiegato',
  employer = 'Datore di lavoro',
  unemployed = 'Disoccupato',
  workingPartner = 'Socio lavorante',
  apprenticeWorker = 'Apprendista Operaio',
  apprenticeEmployee = 'Apprendista Impiegato',
  other = 'Altro'
}

export function TranslateTraineeCategory(category: TraineeCategoryEnum | string): string {
  switch (category){
    case TraineeCategoryEnum.worker:
      return TraineeCategoryTranslationEnum.worker;
    case TraineeCategoryEnum.clerk:
      return TraineeCategoryTranslationEnum.employee;
    case TraineeCategoryEnum.employer:
      return TraineeCategoryTranslationEnum.employer;
    case TraineeCategoryEnum.other:
      return TraineeCategoryTranslationEnum.other;
    case TraineeCategoryEnum.unemployed:
      return TraineeCategoryTranslationEnum.unemployed;
    case TraineeCategoryEnum.workingPartner:
      return TraineeCategoryTranslationEnum.workingPartner;
    default:
      return "";
  }
}

export function InverseTranslateTraineeCategory(category: TraineeCategoryTranslationEnum): string{
  switch (category){
    case TraineeCategoryTranslationEnum.apprenticeWorker:
    case TraineeCategoryTranslationEnum.worker:
      return TraineeCategoryEnum.worker;
    case TraineeCategoryTranslationEnum.apprenticeEmployee:
    case TraineeCategoryTranslationEnum.employee:
      return TraineeCategoryEnum.clerk;
      case TraineeCategoryTranslationEnum.employer:
      return TraineeCategoryEnum.employer;
    case TraineeCategoryTranslationEnum.other:
      return TraineeCategoryEnum.other;
    case TraineeCategoryTranslationEnum.unemployed:
      return TraineeCategoryEnum.unemployed;
    case TraineeCategoryTranslationEnum.workingPartner:
      return TraineeCategoryEnum.workingPartner;
    default:
      return TraineeCategoryEnum.other;
  }
}

export function GetTraineeTypesWithLabel () {
  return [
    {value: TraineeCategoryEnum.clerk, title: TraineeCategoryTranslationEnum.employee},
    {value: TraineeCategoryEnum.employer, title: TraineeCategoryTranslationEnum.employer},
    {value: TraineeCategoryEnum.worker, title: TraineeCategoryTranslationEnum.worker},
    {value: TraineeCategoryEnum.unemployed, title: TraineeCategoryTranslationEnum.unemployed},
    {value: TraineeCategoryEnum.workingPartner, title: TraineeCategoryTranslationEnum.workingPartner},
    {value: TraineeCategoryEnum.other, title: TraineeCategoryTranslationEnum.other},

  ];
}

export function getSelectableTranslatedCategories(){
  return StringEnumToArray(TraineeCategoryEnum)
    .map(category => TranslateTraineeCategory(category)).sort();
}
