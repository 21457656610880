import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-trainee-contract-edit-dialog',
  templateUrl: './trainee-contract-edit-dialog.component.html',
  styleUrls: ['./trainee-contract-edit-dialog.component.scss']
})
export class TraineeContractEditDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { element: any, mode: string},
  ) { }

  ngOnInit() {
  }
}
